import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import "./App.css";
import "./global.css";

import PageNotFound from "./pages/page-not-found/pageNotFound";

import { useEffect } from "react";
import { getOrgName } from "./commons/functions/url";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { dashboardRoutes, routes } from "./commons/store/navigation/routes";

import LogoutWLS from "./pages/logoutWLS/LogoutWLS";
import LoadingPxch from "./components/LoadingPxch/LoadingPxch/LoadingPxch.tsx";

// import PageMaintenance from "./pages/page-maintenance/PageMaintenance";
// import Layout from "./components/layout/Layout";
// import WelcomeHome from "./pages/welcomeHome/WelcomeHome";
// import AccountDisabled from "./components/AccountDisabled/AccountDisabled.js";
// import AccountTrialEnds from "./components/AccountTrialEnds/AccountTrialEnds.js";
// import AccountOnHold from "./components/AccountOnHold/AccountOnHold.js";
// import DashboardMain from "./pages/dasboard/dashboard.js";

const Login = lazy(() => import("./pages/login/login"));
const DashboardMain = lazy(() => import("./pages/dasboard/dashboard"));
const LoginWLS = lazy(() => import("./pages/loginWLS/LoginWLS"));
const AccountOnHold = lazy(() =>
  import("./components/AccountOnHold/AccountOnHold.js")
);
const AccountTrialEnds = lazy(() =>
  import("./components/AccountTrialEnds/AccountTrialEnds.js")
);
const AccountDisabled = lazy(() =>
  import("./components/AccountDisabled/AccountDisabled.js")
);
const WelcomeHome = lazy(() => import("./pages/welcomeHome/WelcomeHome"));
const PageMaintenance = lazy(() =>
  import("./pages/page-maintenance/PageMaintenance")
);
const Layout = lazy(() => import("./components/layout/Layout"));

function App() {
  //permite guardar el subdominio en session storage
  useEffect(() => {
    sessionStorage.setItem("sd", getOrgName());
  }, []);

  return (
    <Routes>
      <Route
        exact
        path="/login"
        element={
          <Suspense fallback={<LoadingPxch />}>
            <Login />
          </Suspense>
        }
      />
      <Route path="/*" element={<PageNotFound />} />
      <Route
        path="/welcomeHome"
        element={
          <Suspense fallback={<LoadingPxch />}>
            <WelcomeHome />
          </Suspense>
        }
      />

      <Route path="/logout" element={<LogoutWLS />} />
      <Route
        path="/checkout-information"
        element={
          <Suspense fallback={<LoadingPxch />}>
            <LoginWLS />
          </Suspense>
        }
      />
      <Route
        path="/maintenance"
        element={
          <Suspense fallback={<LoadingPxch />}>
            <PageMaintenance />
          </Suspense>
        }
      />

      {routes?.map((route) => {
        return (
          <Route
            key={route?.path}
            exact
            path={route?.path}
            element={
              <Suspense fallback={<LoadingPxch />}>
                <ProtectedRoute
                  isAllowed={route?.grants ? route?.grants : true}
                  active={route?.active !== undefined ? route?.active : true}
                >
                  {route?.Component}
                </ProtectedRoute>
              </Suspense>
            }
          />
        );
      })}

      <Route
        path="/"
        element={
          <Suspense fallback={<LoadingPxch />}>
            <Layout active={true} />
          </Suspense>
        }
      >
        <Route
          index
          element={
            <Suspense fallback={<LoadingPxch />}>
              <DashboardMain />
            </Suspense>
          }
        />
        <Route
          exact
          path="/on-hold"
          element={
            <Suspense fallback={<LoadingPxch />}>
              <AccountOnHold />
            </Suspense>
          }
        />
        <Route
          exact
          path="/dissabled"
          element={
            <Suspense fallback={<LoadingPxch />}>
              <AccountDisabled />
            </Suspense>
          }
        />
        <Route
          exact
          path="/free-ends"
          element={
            <Suspense fallback={<LoadingPxch />}>
              <AccountTrialEnds />
            </Suspense>
          }
        />
        {dashboardRoutes?.map((route) => (
          <Route
            key={route?.path}
            exact
            path={route?.path}
            element={
              <Suspense fallback={<LoadingPxch />}>
                <ProtectedRoute
                  isAllowed={route?.grants ? route?.grants : true}
                  redirectOnHold={route?.redirectOnHold || false}
                  active={route?.active !== undefined ? route?.active : true}
                  roles={route?.roles}
                >
                  {route?.Component}
                </ProtectedRoute>
              </Suspense>
            }
          />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
