import React from 'react'
import { useEffect } from 'react';
import auth0 from 'auth0-js';
import { useAuth0 } from '@auth0/auth0-react';

import Auth0 from 'auth0-js';


export default function LogoutWLS() {
    /* const { user, getAccessTokenSilently, isLoading, loginWithPopup } = useAuth0();     */
    
    useEffect(() => {
        let webAuth = new Auth0.WebAuth({
            clientID:"QX18DprLib87zSbxdbiETwfQk6KOGkpz",
            domain: "login.mypxch.com",
            responseType: 'token',
            audience: "https://sellanywhere.us.auth0.com/api/v2/",
            scope: 'openid profile email',
            organization: 'commonsense'
        });

        webAuth.logout({
            returnTo: window.location.origin, // URL to redirect the user after logout
            client_id: 'QX18DprLib87zSbxdbiETwfQk6KOGkpz' // Optional: Specify the client ID to clear the application session
        });

        window.close();

        window.addEventListener("beforeunload", () => {
            // Use window.opener to refer to the main window
            window.opener.postMessage("popupClosed", window.location.origin); // Adjust the target origin accordingly
        });

    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", (evento) => {
            window.opener.postMessage("popupClosed", window.location.origin); // Adjust the target origin accordingly
        });
    }, [])

    return (
        <div>Checking...</div>
    )
}
