import React from 'react'
import "./pageNotFound.css"

export default function PageNotFound() {
  return (
    <div className='body-fof'>
        <div id="main-pnf">
            <div className="fof">
                <span className='fof-title'>404</span>
                <div className='divider'>
                    <span className='fof-subtitle'>Page not found</span>
                    <span className='fof-definition'>Uh oh, we can't find that page! </span>
                    <span className='fof-definition'>It might be an old link or maybe it moved.</span>
                </div>                
                <button className='btn-goback' onClick={(e)=>{e.preventDefault();  window.location.replace(window.location.origin);}}>
                    Go back
                </button>
            </div>
        </div>
    </div>
   
  )
}
