import { defaultWLSRoles } from "../../customize/defaultValues";

/**
 * Function to validate that the user has at least one of the roles specified for wlshopping tool.
 * @param {*} roles Roles that belong to the user
 * @returns
 */
export function checkGrants(roles) {
  //return roles.some(rol => defaultWLSRoles.includes(rol));
}

/**
 * Function to validate that the user has at least one of the roles specified for wlshopping tool.
 * @param {*} roles  Roles that belong to the user
 * @returns
 */
export function checkOneGrant(user) {
  let roles = user?.["https://wherebyfront.vercel.app/roles"] || [];
  let rolesToCompare = defaultWLSRoles;
  return roles?.some((rol) => rolesToCompare.includes(rol));
}

/**
 * Check if the user has the specified roles.
 * @param {string[]} userRoles - The roles of the user.
 * @param {string[]} rolesToSearch - The roles to search for.
 * @param {boolean} [matchAll=false] - Whether all roles in rolesToSearch must be present in userRoles.
 * @returns {boolean} True if the user has the specified roles, false otherwise.
 */
export function hasRoles(user, rolesToSearch, matchAll = false) {
  if (user) {
    if (rolesToSearch && !rolesToSearch.includes("*")) {
      let userRoles = user?.["https://wherebyfront.vercel.app/roles"] || [];
      if (matchAll) {
        return rolesToSearch?.every((role) => userRoles.includes(role));
      } else {
        return rolesToSearch?.some((role) => userRoles.includes(role));
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

// const validateGrants = (user, roles) =>{
//     if(roles && !roles.includes("*")){
//       return hasRoles(user, roles);
//     }else{
//       return true;
//     }
//   }
