import { commonSenseBussinesRules, commonSenseClassNames, commonSenseData } from "../../customize/orgCommonSense/commonsenseStore";
import { developBussinesRules, developClassNames, developData } from "../../customize/orgDevelop/developStore";

import { customizeClassNames, customizeData, defaultBussinesRules } from "../../customize/data";
import { getOrgName } from "./url";

/**
 * Function to get the custome data according to each Orgnization. 
 * Including: colors, logo, others.
 * @returns 
 */
export const getCustomizeData = () => {    
    let organization = getOrgName();

    switch (organization) {
        case "develop":
            return developData;
        case "commonsense":
            return commonSenseData;
        default:
            return customizeData;
    }
   
}

/**
 * Function to get the Bussiness Rules according to each Orgnization.
 * @returns 
 */
export const getCustomeBussinessRules = () => {    
    let organization = getOrgName();

    switch (organization) {
        case "commonsense":
            return commonSenseBussinesRules;
        case "develop":
            return developBussinesRules;
        default:
            return defaultBussinesRules;
    }
   
}

/**
 * Function to get the custome classNames acording to organizations
 * Including: colors, logo, others.
 * @returns 
 */
export const getCustomizeClassName = () => {    
    let organization = getOrgName();

    switch (organization) {
        case "commonsense":
            return commonSenseClassNames;
        case "develop":
            return developClassNames;
        default:
            return customizeClassNames;
    }
   
}
