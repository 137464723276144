import React from "react";

function LoadingPxch() {
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <div className="relative flex justify-center items-center">
        <div className="absolute animate-spin rounded-full h-48 w-48 border-t-4 border-b-4 border-brand-primary"></div>
        <div className="relative w-28 h-28">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            className="absolute h-28 w-28 " 
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 225.31 266.28"
          >
            <path
              fill="#f2b84b"
              d="M225.3,21.29c-.18-5.61-2.64-11.08-6.71-14.95-4.43-4.22-10.04-6.34-16.84-6.34-7.86,0-13.83,2.9-17.92,8.7l-71.18,88.59-17.76-21.94c-7.86-9.71-22.08-11.14-31.79-3.29-.12.1-.24.19-.36.29-9.73,7.87-11.2,22.16-3.26,31.84l25,30.48L5.12,228.38c-3.42,4.44-5.12,9.22-5.12,14.34,0,7.17,2.05,12.89,6.15,17.15,4.1,4.27,9.38,6.4,15.87,6.4,8.19,0,14.85-3.41,19.97-10.24l68.62-86.03,56.69,64.94c2.6,3.07,2.34,2.3,5.79,6.24,10.8,12.34,25.27,9.45,32.21,3.47,6.93-5.97,10.03-17.74,2.7-27.58l-4.69-6.81-64.02-78.66,80.91-95.25c3.57-4.64,5.28-9.67,5.11-15.07Z"
            />
          </svg>
          <svg
            id="Layer_1"
            className="animate-pulse absolute h-28 w-28 left-[2px]"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 240.74 266.28"
          >
            <path
              fill="#f2b84b"
              d="M232.55,118.04c-1.63-8.37-3.05-15.41-4.93-23.26-1.18-4.8-2.4-9.95-6.99-12.4-6.74-2.95-14.85,9.24-20.49,14.52-.32.34-11.67,12.95-11.61,12.82-2.21-5.99-3.5-13.66-8.25-18.19-.13-.02,0,0,0,0h0c-3.83-3.94-54.81,6.82-58.52,11.3l-.34.47c-2.92,4.82-1.46,10.94-.68,16.08,1.61,9.8,3.04,16.91,5.01,25.85,3.14,10.28,5.19,30.85,13.77,30.85,10.12,0-2.88-51.28-3.25-61.67,12.52-3.18,22.1-5.67,34.82-7.94,3.66,7.95,4.54,21.07,13.79,24.46,2.1.77,3.55-.47,3.55-.47,4.46-2.42,7.19-6.7,10.55-10.14,4.5-5.27,11.73-13.67,16.27-18.87,5.15,19.74,9.71,41.2,13.4,60.94-6.96-2.35-34.83-21.27-39.43-16.57-4.67,4.82,2.43,20.41,3.35,29.72-12.16,2.94-27.69,5.23-34.51,6.11-3.73.73-12.34-.9-12.33,2.75,3.96,4.66,41.85-.16,51.03-2.53,4.95-1.17,3.22-7.21,2.84-10.85-.7-4.01-1.9-10.73-2.37-14.66,9.97,4.79,28.44,20.28,39.24,20.14l1.14-.18c5.38-4.54,2.29-13.39,1.64-19.62-1.95-12.83-4.1-25.19-6.72-38.65h0Z"
            />
            <g>
              <path
                fill="#f2b84b"
                d="M225.3,21.29c-.18-5.61-2.64-11.08-6.71-14.95-4.43-4.22-10.04-6.34-16.84-6.34-7.86,0-13.83,2.9-17.92,8.7l-71.18,88.59-17.76-21.94c-7.86-9.71-22.08-11.14-31.79-3.29-.12.1-.24.19-.36.29-9.73,7.87-11.2,22.16-3.26,31.84l25,30.48L5.12,228.38c-3.42,4.44-5.12,9.22-5.12,14.34,0,7.17,2.05,12.89,6.15,17.15,4.1,4.27,9.38,6.4,15.87,6.4,8.19,0,14.85-3.41,19.97-10.24l68.62-86.03,56.69,64.94c2.6,3.07,2.34,2.3,5.79,6.24,10.8,12.34,25.27,9.45,32.21,3.47,6.93-5.97,10.03-17.74,2.7-27.58l-4.69-6.81-64.02-78.66,80.91-95.25c3.57-4.64,5.28-9.67,5.11-15.07Z"
              />
              <circle fill="#f2b84b" cx="26.54" cy="30.68" r="23.22" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default LoadingPxch;
