export const customizeData = {
  sidebarLogo:
    "https://pxch.s3.ca-central-1.amazonaws.com/logo/primary_stack_d.webp",
  footerLogo:
    "https://pxch.s3.ca-central-1.amazonaws.com/logo/third_filled_horiz_d.webp",
  topMeetingLogo:
    "https://pxch.s3.ca-central-1.amazonaws.com/logo/primary_stack_d.webp",
  invitationLogo:
    "https://pxch.s3.ca-central-1.amazonaws.com/logo/primary_stack_d.webp",
  meetingRoomImage: "/assets/backgrounds/meetingBg.webp",
  waitingRoomImage: "/assets/backgrounds/meetingBg.webp", //"https://www.powerhomebiz.com/wp-content/uploads/2020/05/work_from_home_woman.png",
  chooseWebsitImage: "/assets/pxch/choose_store_bg_y.webp",
  instantMeetingIcon: "yellow",
  topMeetingTxtColor: "#2C353D",
  topMeetingBgColor: "#FFFFFF",
  topMeetingBtnColor: {
    bg_standart: "#f2b84b",
    bg_hover: "#FFFFFF",
    bg_active: "#2C353D",
    text_standart: "#2C353D",
    text_active: "#FFFFFF",
    text_hover: "#f2b84b",
    border_standart: "#f2c777",
    border_hover: "#f2b84b",
    border_active: "#2C353D",
  },
  goPreviewButton: {
    bg_standart: "#f2b84b",
    bg_hover: "#FFFFFF",
    text_standart: "#FFFFFF",
    text_hover: "#f2b84b",
    border_standart: "#f2b84b",
    border_hover: "#f2b84b",
  },
  addNewButton: {
    bg_standart: "#f2b84b",
    bg_hover: "#FFFFFF",
    text_standart: "#FFFFFF",
    text_hover: "#f2b84b",
    border_standart: "#f2b84b",
    border_hover: "#f2b84b",
  },
  saveButton: {
    bg_standart: "#f2b84b",
    bg_hover: "#FFFFFF",
    text_standart: "#FFFFFF",
    text_hover: "#f2b84b",
    border_standart: "#f2b84b",
    border_hover: "#f2b84b",
  },
  cancelButton: {
    bg_standart: "#2C353D",
    bg_hover: "#FFFFFF",
    text_standart: "#FFFFFF",
    text_hover: "#2C353D",
    border_standart: "#2C353D",
    border_hover: "#2C353D",
  },
  homePageLogo:
    "https://pxch.s3.ca-central-1.amazonaws.com/logo/primary_stack_w.webp",
  textPrimaryColor: "#748FB5",
  textSecondaryColor: "#b11e55",
  textHeadlineColor: "#748FB5", //color de texto en los headers de las cards
  textLinkColor: "#748FB5", //para mostrar los textos de next meeting
  textMainButton: "#f2b84b", //text into main button
  textSelected: "#f2b84b", //color para el texto seleccionado en el dashboard
  textSidebar: "#000000",
  bgMainButton: "#2C353D", //background of the main button in dashboard Set Up a new meeting
  bgFiltersButton: "#748FB5", //background para el boton de filtros
  textFiltersButton: "#f2b84b", //color de texto para el filtro
  bgSidebar: "#FFFFFF",
  pageTitle: "#2C353D", //color para el texto en cada pagina
  bgGoPreviewButton: "#000000",
  catalogBgButtonSelected: "#b11e55",
  catalogTextButtonSelected: "#FFFFFF",
  activeTextColor: "#f2b84b",
  buttons: {
    successBg: "",
    errorBg: "",
    okBg: "",
    cancelBg: "",
    successText: "",
    errorText: "",
    okText: "",
    cancelText: "",
  },
};

export const customizeClassNames = {
  cancelButton:
    "text-center border rounded-none bg-[#2C353D] w-full px-4 py-2 font-semibold text-white hover:opacity-50",
  acceptButton:
    "text-center border !rounded-none border-[#f2c777] bg-[#f2c777] text-[#2C353D] w-full px-4 py-2  font-semibold hover:bg-white disabled:bg-[#BAC3CA] disabled:border-[#BAC3CA]",
  setUpMeetingButtonEffect:
    "bg-brand-dark  text-white before:!bg-brand-primary hover:shadow-illustration-main before:text-brand-dark",
  standartButton: "",
};

export const customizeHomePageData = {
  navbarBg: "#000000",
  navbarLogo: "https://workinglive.us/wp-content/uploads/2020/07/wl-logo.png",
  navbarButtonBg: "#b11e55",
  navbarButtonBgHover: "rgb(177 30 85 / 77%)",
  footerBg: "#313131",
};

export const defaultBussinesRules = {
  shopping: {
    freeShippingEnable: false,
    enableCoupon: false,
    countryCurrency: [
      {
        title: "United States | English",
        currency: "usd",
        country: "US",
        language: "en",
      },
    ],
    checkoutAsGuest: true,
    payment: {
      nmiAvailable: true,
      ewalletAvailable: false,
    },
    subscriptions: {
      active: true,
      displayinReviewCart: true,
      defaultSelected: "NOSUBS", //"ONLYSUBS", "STANDART", NOSUBS
      porcentageDiscount: 0,
      types: {
        onlySubsAllow: true,
        onlySubsDetails: [
          "Earn Rewards.",
          "Free Shipping: if date is set to 1st-5th",
          "Cancel Anytime",
        ],
        onlySubsLabel: "Subscribe",
        onlySubsDescription:
          "Suscribe and get your automatic shipment monthly.",
        standartSubsAllow: false,
        standartSubsDetails: [],
        standartSubsLabel: "",
        standartSubsDescription:
          "Suscribe and get your product with your order and also your automatic shipment monthly.",
        noSubsLabel: "One-time Purchase",
      },
    },
    enableRemoveFromReviewCart: false,
    coupons: {
      enable: false,
    },
  },
  enableProductCatalog: false,
  enableCreateAffiliateStore: true,
  catalog: {
    addDirectToCart: true,
  },
  cohost: {
    type: "all", //["only-user", "none", "all"]
  },
  affiliateStore: undefined,
  useAffiliateStore: false,
  account: {
    type: "allr", //["only-user", "all"]
  },
  completeName: "PXCH",
  presentations:{
    shareModeStart: "host_control" //["host_control", "guest_control"]
  }
};

//You must use the same email that you use to login your office account
