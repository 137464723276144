export const commonSenseData = {
  sidebarLogo: "/assets/logos/logoCS.png",
  footerLogo: "/assets/logos/logoCS.png",
  topMeetingLogo: "/assets/logos/logoCS.png",
  invitationLogo:
    "https://pxch.s3.ca-central-1.amazonaws.com/logo/primary_stack_d.webp",
  waitingRoomImage: "https://commonsensew4.s3.amazonaws.com/bg_meeting_cs.jpg",
  meetingRoomImage: "https://commonsensew4.s3.amazonaws.com/bg_meeting_cs.jpg",
  chooseWebsitImage: "/assets/pxch/choose_store_bg_w.webp",
  instantMeetingIcon: "blue",
  topMeetingTxtColor: "#000000",
  topMeetingBgColor: "#CCEEF9",
  topMeetingBtnColor: {
    bg_standart: "transparent",
    bg_active: "#000000",
    bg_hover: "#000000",
    text_standart: "#000000",
    text_active: "#FFFFFF",
    text_hover: "#FFFFFF",
  },
  goPreviewButton: {
    bg_standart: "#000000",
    bg_hover: "#FFFFFF",
    text_standart: "#FFFFFF",
    text_hover: "#000000",
    border_standart: "#000000",
    border_hover: "#000000",
  },
  addNewButton: {
    bg_standart: "#000000",
    bg_hover: "#FFFFFF",
    text_standart: "#FFFFFF",
    text_hover: "#000000",
    border_standart: "#000000",
    border_hover: "#000000",
  },
  homePageLogo: "https://workinglive.us/wp-content/uploads/2020/07/wl-logo.png",
  textPrimaryColor: "#000000",
  textSecondaryColor: "#00A9E8",
  textHeadlineColor: "#00A9E8",
  textLinkColor: "#00A9E8",
  textMainButton: "#00A9E8",
  textSelected: "#00A9E8",
  bgMainButton: "#000000",
  bgFiltersButton: "#000000", //background para el boton de filtros
  textFiltersButton: "#FFFFFF", //color de texto para el filtro
  bgSidebar: "#FFFFFF",
  pageTitle: "#000000",
  catalogBgButtonSelected: "#00A9E8",
  catalogTextButtonSelected: "#000000",
  activeTextColor: "#00A9E8",
};

export const commonSenseClassNames = {
  cancelButton:
    "text-center !rounded-none border-black bg-white border-2 w-full px-4 py-1  font-medium text-black shadow-sm hover:bg-[#f9f9f9]",
  acceptButton:
    "text-center !rounded-none !bg-black w-full  font-medium !text-white hover:opacity-50",
  setUpMeetingButtonEffect:
    "bg-brand-dark text-white before:!bg-[#748FB5] hover:shadow-[#74777E] ",
};

/**
 * interface CountryCurrency {
 * title?: string;
 *  currency?: string;
 * country: string;
 * language?: string;
 * }
 */
export const commonSenseBussinesRules = {
  shopping: {
    freeShippingEnable: true,
    freeShippingAmount: 145,
    justShowFreeShipping: true,
    enableCoupon: true,
    countryCurrency: [
      {
        title: "Australia | English",
        currency: "aud",
        country: "AU",
        language: "en",
      },
      {
        title: "Canada | English",
        currency: "cad",
        country: "CA",
        language: "en",
      },
      {
        title: "New Zealand | English",
        currency: "nzd",
        country: "NZ",
        language: "en",
      },
      {
        title: "United States | English",
        currency: "usd",
        country: "US",
        language: "en",
      },
      {
        title: "United States | Spanish",
        currency: "usd",
        country: "US",
        language: "es",
      },
    ],
    subscriptions: {
      active: true,
      displayinReviewCart: true,
      defaultSelected: "NOSUBS",
      porcentageDiscount: 20,
      defaultPercentageMessage: "Save up to $percentage on all items!",
      types: {
        onlySubsAllow: true,
        onlySubsDetails: [
          "Earn Rewards.",
          "Free Shipping: if date is set to 1st-5th",
          "Cancel Anytime",
        ],
        onlySubsLabel: "Add To Monthly Subscription",
        onlySubsDescription:
          "Suscribe and get your automatic shipment monthly.",
        standartSubsAllow: false,
        standartSubsDetails: [],
        standartSubsLabel: "Add To both Today's Order & Monthly Subscription",
        standartSubsUsersType: 1,
        standartSubsDescription:
          "Suscribe and get your product with your order and also your automatic shipment monthly.",
        noSubsLabel: "Add to Today's Order",
      },
    },
    checkoutAsGuest: true,
    payment: {
      nmiAvailable: true,
      ewalletAvailable: true,
      partialEwalletPayment: true,
      rewardPointsAvailable: false
    },
    enableRemoveFromReviewCart: true,
    coupons: {
      enable: true,
    },
  },
  enableProductCatalog: true,
  enableCreateAffiliateStore: false,
  catalog: {
    excludedProducts: [
      "ENROLL069",
      "ENROLL019",
      "ENROLL008",
      "ENROLL017",
      "ENROLL023",
      "ENROLL005",
      "ENROLL020",
      "ENROLL024",
      "ENROLL022",
      "ENROLL009",
      "ENROLL018",
      "ENROLL025",
    ],
    enrollmentCategory: ["164552899"],
    excludedCategories: ["167096815", "164552899"],
    addDirectToCart: false,
  },
  cohost: {
    type: "only-user", //["only-user", "none", "all"]
  },
  affiliateStore: "https://common-sense-builder-front.vercel.app/",
  useAffiliateStore: true,
  loginAsUser: {
    available: true,
    loginMessage:
      "If you already have an account in Common Sense, login to have a fast and easy checkout.",
    loginLabel: "Company ID:",
  },
  account: {
    type: "only-user", //["only-user", "all"]
    roles: {
      fieldName: "customer_type_id",
      values: ["2", "1"],
    },
    joinPage: "https://common-sense-builder-front.vercel.app/commonsense/join",
  },
  completeName: "Common Sense Wellness",
  sponsorCompany: "Common Sense Wellness",
  companyLogo:"/assets/logos/logoCS.png",
  org_newCompany: { _id: "new_org", isNew: false, company_name: "Common Sense Wellness" },
  termsAndConditions: {
    distributorUrl:
      "https://common-sense-builder-front.vercel.app/distributor-agreement",
    privacyPoliciyUrl:
      "https://common-sense-builder-front.vercel.app/privacy-policy",
    policiesProceduresUrl:
      "https://common-sense-builder-front.vercel.app/policies-procedures",
  },
};

export const commonSenseShipping = {
  shipment: {
    to_location: {
      company: "commonsense",
      first_name: "common",
      last_name: "sense",
      nick_name: "commonsense",
      address1: "3327 N Eagle Rd",
      address2: "110-126",
      address3: "string",
      city: "Meridian",
      state: "string",
      country: "US",
      postal_code: "83646",
      phone: "9999999999",
      email: "commonsense@support.com",
    },
    from_location: {
      company: "commonsense",
      first_name: "common",
      last_name: "sense",
      nick_name: "commonsense",
      address1: "3327 N Eagle Rd",
      address2: "110-126",
      address3: "string",
      city: "Meridian",
      state: "string",
      country: "US",
      postal_code: "83646",
      phone: "9999999999",
      email: "commonsense@support.com",
    },
    parcels: [
      {
        length: 1,
        width: 1,
        height: 1,
        weight: 1,
        package_type: "parcel",
        parcel_items: [
          {
            id: 0,
            item_id: "string",
            name: "string",
            weight: 0,
            quantity: 0,
            price: 0,
            cost: 0,
            customs_data: {
              content_type: "merchandise",
              no_delivery: "return",
              value: 0,
            },
          },
        ],
      },
    ],
  },
  shippingRates: [
    {
      rate: 7.95,
      country: "US",
      currency: "usd",
      type: "Standard",
      service: "Standard Shipping",
    },
    {
      rate: 14.95,
      country: "CA",
      currency: "cad",
      type: "Standard",
      service: "Standard Shipping",
    },
    {
      rate: 14.95,
      country: "AU",
      currency: "aud",
      type: "Standard",
      service: "Standard Shipping",
    },
    {
      rate: 19.95,
      country: "NZ",
      currency: "nzd",
      type: "Standard",
      service: "Standard Shipping",
    },
  ],
};
