import { Navigate, Outlet } from "react-router-dom"
import { hasRoles } from "../commons/functions/roles"
import { useAuth0 } from "@auth0/auth0-react";
import { wlsDisabledRoles } from "../customize/defaultValues";
import useSubscriptionStore from "../commons/store/userSubscription/userSubscription";

export const ProtectedRoute = ({isAllowed, children, active, roles, redirectOnHold}) => {
    const { user } = useAuth0();
    const { userSubs } = useSubscriptionStore();

    if(!isAllowed){
        return <Navigate to={"/forbidden"} />
    }
    if(!active){
        return <Navigate to={"/maintenance"} />
    }
    if(user && redirectOnHold && hasRoles(user, wlsDisabledRoles)){
        return <Navigate to={"/on-hold"} />
    }
    if(userSubs?.expired){
        return <Navigate to={"/free-ends"} />
    }
    if(user && !hasRoles(user, roles)){
        return <Navigate to={"/forbidden"} />
    }
    return children ? children : <Outlet />
}