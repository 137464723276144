import { create } from "zustand";

const useSubscriptionStore = create((set) => ({
    userSubs: {
        isFree: false,
        expired: false,
        expirationDate: "",
        expirationDays: 0,
        trialEndsAt: "", 
        nextBillingDate: "", 
        activeSubs: undefined,
    },
    updateSubscription: (subscription) => {
        set({ userSubs: subscription })
    },
  }));
  
export default useSubscriptionStore;
  