export const developData  = {    
  sidebarLogo: "https://pxch.s3.ca-central-1.amazonaws.com/logo/primary_stack_d.webp",
    footerLogo: "https://pxch.s3.ca-central-1.amazonaws.com/logo/third_filled_horiz_d.webp",
    topMeetingLogo: "https://pxch.s3.ca-central-1.amazonaws.com/logo/primary_stack_d.webp",
    invitationLogo:"https://pxch.s3.ca-central-1.amazonaws.com/logo/primary_stack_d.webp",
    meetingRoomImage: "/assets/backgrounds/meetingBg.webp",
    waitingRoomImage:"/assets/backgrounds/meetingBg.webp",//"https://www.powerhomebiz.com/wp-content/uploads/2020/05/work_from_home_woman.png",
    chooseWebsitImage: "/assets/pxch/choose_store_bg_y.webp", 
    instantMeetingIcon: "yellow",
    topMeetingTxtColor: "#2C353D",
    topMeetingBgColor: "#FFFFFF",
    topMeetingBtnColor: 
    {
        bg_standart: "#f2b84b",
        bg_hover:"#FFFFFF",
        bg_active: "#2C353D", 
        text_standart: "#2C353D",
        text_active: "#FFFFFF", 
        text_hover:"#f2b84b",
        border_standart: "#f2c777",
        border_hover:"#f2b84b",
        border_active: "#2C353D", 
    },
    goPreviewButton:{
        bg_standart: "#f2b84b",
        bg_hover:"#FFFFFF",
        text_standart: "#FFFFFF",
        text_hover:"#f2b84b",
        border_standart: "#f2b84b",
        border_hover:"#f2b84b",
    },
    homePageLogo: "https://pxch.s3.ca-central-1.amazonaws.com/logo/primary_stack_w.webp",
    textPrimaryColor: "#748FB5",
    textSecondaryColor: "#b11e55",
    textHeadlineColor: "#748FB5",//color de texto en los headers de las cards
    textLinkColor: "#748FB5",//para mostrar los textos de next meeting
    textMainButton: "#f2b84b",//text into main button
    textSelected: "#f2b84b",//color para el texto seleccionado en el dashboard
    textSidebar: "#000000",
    bgMainButton: "#2C353D",//background of the main button in dashboard Set Up a new meeting
    bgFiltersButton:"#748FB5",//background para el boton de filtros 
    textFiltersButton:"#f2b84b", //color de texto para el filtro
    bgSidebar: "#FFFFFF",
    pageTitle: "#2C353D",//color para el texto en cada pagina
    bgGoPreviewButton: "#000000",     
    catalogBgButtonSelected: "#b11e55",
    catalogTextButtonSelected: "#FFFFFF",
    activeTextColor: "#f2b84b",
    buttons: {
        successBg: "",
        errorBg: "",
        okBg: "",
        cancelBg: "",
        successText: "",
        errorText: "",
        okText: "",
        cancelText: "",
    }
}

export const developClassNames  = {    
  cancelButton: "text-center !rounded-none border-black bg-white border-2 w-full px-4 py-1  font-medium text-black shadow-sm hover:bg-[#f9f9f9]",
  acceptButton: "text-center !rounded-none !bg-black w-full  font-medium !text-white hover:opacity-50",
  setUpMeetingButtonEffect: "bg-brand-dark text-white before:!bg-brand-primary hover:shadow-illustration-main before:text-brand-dark",
}

/**
 * interface CountryCurrency {
 * title?: string;
 *  currency?: string;
 * country: string;
 * language?: string;
 * }
 */
export const developBussinesRules = {
  shopping: {
    freeShippingEnable: true,
    freeShippingAmount : 145, 
    justShowFreeShipping : true,
    enableCoupon: true,
    countryCurrency: [
      {
        title: "Australia | English",
        currency: "aud",
        country: "AU",
        language: "en",
      },
      {
        title: "Canada | English",
        currency: "cad",
        country: "CA",
        language: "en",
      },
      {
        title: "New Zealand | English",
        currency: "nzd",
        country: "NZ",
        language: "en",
      },
      {
        title: "United States | English",
        currency: "usd",
        country: "US",
        language: "en",
      },
      {
        title: "United States | Spanish",
        currency: "usd",
        country: "US",
        language: "es",
      },
    ],
    subscriptions:{
      active: true,
      benefits:[
        "Earn Rewards.",
        "Free Shipping: if date is set to 1st-5th",
        "Cancel Anytime",
      ]
    }, 
    coupons:{
      enable:true,
    }
  }, 
  enableProductCatalog:false,
  enableCreateAffiliateStore: true,
  catalog:{
    excludedProducts: ["ENROLL069","ENROLL019", "ENROLL008", "ENROLL017", "ENROLL023", "ENROLL005", "ENROLL020", "ENROLL024","ENROLL022", "ENROLL009", "ENROLL018" ],    
  },
  cohost:{
    type:"all",//["only-user", "none", "all"]
  },  
  affiliateStore:undefined,
  useAffiliateStore:false,
  loginAsUser: {
    available:true, 
    loginMessage: "If you already have an account in Common Sense, login to have a fast and easy checkout.",
    loginLabel: "Company ID:"
  }, 
  account:{
    type:"all",//["only-user", "all"]
    roles:{}, 
    joinPage:undefined, 
  },
  completeName: "Develop WorkingLive",
  presentations:{
    shareModeStart: "host_control" //["host_control", "guest_control"]
  }
}

export const commonSenseShipping  = {
    shipment: {
      to_location: {
        company: "commonsense",
        first_name: "common",
        last_name: "sense",
        nick_name: "commonsense",
        address1: "3327 N Eagle Rd",
        address2: "110-126",
        address3: "string",
        city: "Meridian",
        state: "string",
        country: "US",
        postal_code: "83646",
        phone: "9999999999",
        email: "commonsense@support.com",
      },
      from_location: {
        company: "commonsense",
        first_name: "common",
        last_name: "sense",
        nick_name: "commonsense",
        address1: "3327 N Eagle Rd",
        address2: "110-126",
        address3: "string",
        city: "Meridian",
        state: "string",
        country: "US",
        postal_code: "83646",
        phone: "9999999999",
        email: "commonsense@support.com",
      },
      parcels: [
        {
          length: 1,
          width: 1,
          height: 1,
          weight: 1,
          package_type: "parcel",
          parcel_items: [
            {
              id: 0,
              item_id: "string",
              name: "string",
              weight: 0,
              quantity: 0,
              price: 0,
              cost: 0,
              customs_data: {
                content_type: "merchandise",
                no_delivery: "return",
                value: 0,
              },
            },
          ],
        },
      ],
    },
    shippingRates:[
      {
        rate: 7.95,
        country: "US",
        currency: "usd",
        type: "Standard",
        service: "Standard Shipping",
      },
      {
        rate: 14.95,
        country: "CA",
        currency: "cad",
        type: "Standard",
        service: "Standard Shipping",
      },
      {
        rate: 14.95,
        country: "AU",
        currency: "aud",
        type: "Standard",
        service: "Standard Shipping",
      },
      {
        rate: 19.95,
        country: "NZ",
        currency: "nzd",
        type: "Standard",
        service: "Standard Shipping",
      },
    ]
}



